<template>
  <div>
    <v-app-bar
      id="app-bar"
      absolute
      app
      color="transparent"
      flat
      height="75"
    >
      <v-btn
        class="mr-3"
        elevation="1"
        fab
        small
        @click="setDrawer(!drawer)"
      >
        <v-icon v-if="value">
          mdi-view-quilt
        </v-icon>

        <v-icon v-else>
          mdi-dots-vertical
        </v-icon>
      </v-btn>
      <v-toolbar-title
        class="hidden-sm-and-down font-weight-light"
        v-text="$route.name"
      />
      <v-spacer></v-spacer>
      <template v-if="loggedInUser != 'ssb'">
        <v-alert
            v-if="dasStatus =='ONLINE'"
            close-icon="true"
            type="success"
        >
          SSB DAS is currently open
        </v-alert>
        <v-alert
            v-if="dasStatus =='OFFLINE'"
            close-icon="true"
            type="error"
        >
          SSB DAS is currently closed
        </v-alert>
      </template>
      <v-spacer />
      <v-icon aria-hidden="false">
        mdi-account
      </v-icon>
      {{ loggedUser }}
      <v-btn
        class="ml-2 pr-4"
        min-width="0"
        text
        @click="logoutDialog = true"
      >
        <v-icon>mdi-logout variant</v-icon>
      </v-btn>
    </v-app-bar>
    <v-dialog
      v-model="logoutDialog"
      width="320"
    >
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="logoutDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Logout</v-toolbar-title>
          <v-spacer />
        </v-toolbar>
        <v-card-title>
          <v-spacer />
          Are you sure you want to logout ?
        </v-card-title>
        <v-card-text class="pb-6 pt-12 text-center">
          <v-btn
            color="red"
            text
            @click="logout"
          >
            Yes
          </v-btn>
          <v-btn
            class="mr-3"
            color="primary"
            @click="logoutDialog = false"
          >
            No
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  // Components
  // Utilities
  import { mapState, mapMutations } from 'vuex'
  import axios from "axios";
  export default {
    name: 'DashboardCoreAppBar',
    props: {
      value: {
        type: Boolean,
        default: false,
      },
    },
    data () {
      return {
        logoutDialog: false,
        dasStatus: "",
        loggedUser: this.$store.getters.loggedInUser,
      }
    },
    computed: {
      ...mapState(['drawer', 'loggedInUser']),
    },
    created () {
      this.setup()
      this.getDasStatus()
      console.log('timer started')
    },
    methods: {
      ...mapMutations({
        setDrawer: 'SET_DRAWER',
      }),
      async logout () {
        window.location.href = '/login'
        localStorage.clear()
        this.$store.commit('clearUserData')
      },
      setup () {
        // if (process.client) {
        addEventListener('mousemove', this.resetTimer, false)
        addEventListener('mousedown', this.resetTimer, false)
        addEventListener('keypress', this.resetTimer, false)
        addEventListener('DOMMouseScroll', this.resetTimer, false)
        addEventListener('mousewheel', this.resetTimer, false)
        addEventListener('touchmove', this.resetTimer, false)
        addEventListener('MSPointerMove', this.resetTimer, false)

        this.startTimer()
        console.log('timer started from setup')
        // }
      },
      startTimer () {
        this.timeoutID = window.setTimeout(this.goInactive, 400000)
      },
      resetTimer (e) {
        window.clearTimeout(this.timeoutID)
        this.goActive()
      },
      goActive () {
        this.startTimer()
      },
      async getDasStatus() {
        const JWTToken = this.$store.getters.accessToken
        axios.get('/das/das-status/')
            .then(res => {
              if (res.status === 200) {
                this.dasStatus = res.data.das_status
              } else if (res.status === 202) {
                console.log('202')
              } else {
                console.log('error')
              }
            })
      },
      goInactive () {
        window.clearTimeout(this.timeoutID)
        this.$store.commit('clearUserData')
        alert('Your Session has ended')
        window.location.href = '/login'
      },
    },
  }
</script>
